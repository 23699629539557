import React, { useCallback, useState } from 'react';
import { useResource } from '@tg/core/hooks';
import { useTranslation } from 'react-i18next';
import {
  InputFile,
  Heading,
  List,
  Button,
  PaginationControl,
} from '@tg/core/components';

const EmployeeDocuments = () => {
  const [page, setPage] = useState(1);

  const { data, postResource, deleteResource, pagination } = useResource(
    { url: `employees/documents?page=${page}&pagesize=10` },
    true,
  );

  const onDelete = id => {
    deleteResource({ id });
  };

  const onDrop = useCallback(dropped => {
    const formData = new FormData();
    dropped.forEach(file => {
      formData.append('files[]', file);
    });
    postResource({ formData, headers: {} });
  }, []);

  const { t } = useTranslation(['profile', 'documents']);

  return (
    <>
      <Heading level='h4'>{t('documents')}</Heading>
      <div className='mb-4'>
        <InputFile onDrop={onDrop} multiple />
      </div>
      {data && !!data.length && (
        <List
          noShadow
          headings={[{ text: '' }, { text: '', align: 'right' }]}
          items={data.map(({ id, filename }) => ({
            id,
            columns: [
              filename,
              <Button
                color='secondary'
                appearance='outline'
                size='small'
                onClick={() => onDelete(id)}
              >
                {t('documents:delete')}
              </Button>,
            ],
          }))}
        />
      )}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
    </>
  );
};

export default EmployeeDocuments;
