import React from 'react';
import TeamedLogo from '@tg/core/assets/images/Teamed_T_Black.png';
import TeamedText from '@tg/core/assets/images/Teamed_Logo_Navy.png';

export default () => {
  return (
    <picture>
      <source media='(min-width: 767px)' srcSet={TeamedText} />
      <img src={TeamedLogo} alt='Teamed Logo' className='logo' />
    </picture>
  );
};
